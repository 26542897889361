$brand: "fr";
$navbar-height: 100px !default;
$navbar-height-sm: 84px !default;
$brand-navbar-top-height: auto !default;
$brand-navbar-top-height-sm: 44px !default;
$brand-navbar-top-background: none;
$brand-navbar-img-padding: 18px 0;
$brand-navbar-width: 70px;
$brand-navbar-width-sm: 48px;
$brand-navbar-box-shadow: none;
